var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-progress-linear',{attrs:{"indeterminate":_vm.loading}}),_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.synchronization))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","depressed":"","small":"","disabled":_vm.companiesLoading},on:{"click":_vm.pingXGEST}},[_vm._v("PING")]),_c('v-btn',{attrs:{"color":"primary","depressed":"","small":"","loading":_vm.companiesLoading},on:{"click":_vm.synchronizeCompanies}},[_vm._v(_vm._s(_vm.lang.syncCompanies))]),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","depressed":"","small":"","loading":_vm.productsLoading},on:{"click":_vm.synchronizeProducts}},[_vm._v(_vm._s(_vm.lang.syncProducts))]),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","icon":"","color":"success","small":""},on:{"click":_vm.watchOptions}},[_c('v-icon',[_vm._v("mdi-cached")])],1)],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.synchros,"hide-default-footer":true,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"show-select":false,"page":_vm.page,"loading-text":_vm.lang.loading,"no-results-text":_vm.lang.noResults,"no-data-text":_vm.lang.noResults,"options":_vm.options,"server-items-length":_vm.totalItems,"disable-sort":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:options":function($event){_vm.options=$event},"click:row":_vm.openSynchro},scopedSlots:_vm._u([{key:"header.id",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.starts",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.finish",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.log",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.status",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"item.log",fn:function(ref){
var item = ref.item;
return [(item.log)?_c('div',[_vm._v(" "+_vm._s(item.log.log)+" ")]):_vm._e()]}},{key:"item.starts",fn:function(ref){
var item = ref.item;
return [(item.starts)?_c('div',[_vm._v(" "+_vm._s(item.starts.setLocale("es").toFormat('dd/MM/yyyy hh:mm'))+" ")]):_vm._e()]}},{key:"item.finish",fn:function(ref){
var item = ref.item;
return [(item.finish)?[_vm._v(" "+_vm._s(item.finish.setLocale("es").toFormat('dd/MM/yyyy hh:mm'))+" ")]:_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?[_vm._v(" "+_vm._s(item.status)+" ")]:_vm._e()]}}])})],1)],1)],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-dialog',{attrs:{"width":"700px","persistent":""},model:{value:(_vm.productsDialog),callback:function ($$v) {_vm.productsDialog=$$v},expression:"productsDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.synchronization))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","icon":"","color":"success","small":""},on:{"click":function($event){_vm.productsDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider',{staticClass:"my-3 mx-5"}),_c('v-card-text',{staticClass:"pb-10"},[_c('v-progress-linear',{attrs:{"striped":"","height":"25px"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('p',{staticClass:"mb-0 mt-5"},[_vm._v("La sincronización de productos esta ejecutandose, por favor espere un momento.")]),_c('p',[_vm._v("Se le avisara a su correo de notificaciones cuando el proceso de sincronización haya finalizado.")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-3 py-6 mx-2",attrs:{"dark":"","depressed":"","tile":"","color":"pb-peluqueria"},on:{"click":function($event){_vm.productsDialog = false}}},[_c('span',{staticClass:"grey--text text--darken-3"},[_vm._v(_vm._s(_vm.lang.close))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }